import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/global/layout/Layout';
import FeatureHero from '../components/global/FeatureHeroSection/FeatureHero';
import SEO from '../components/global/seo';

export const Wrapper = styled.div`
	width: 100%;
`;

const errorPage = ({ data }) => {
	const {
		title,
		subtitle,
		image,
		seoTitle,
		seoImage,
		seoJsonSchema,
	} = data.allContentful404Page.nodes[0];

	return (
		<Layout>
			<SEO title={seoTitle} image={seoImage} seoJsonSchema={seoJsonSchema} />
			<Wrapper>
				<FeatureHero
					buttonText="Home page"
					title={title}
					subTitle={subtitle}
					image={image}
					buttonLink={'/'}
					imgSectionWidth={'47%'}
				/>
			</Wrapper>
		</Layout>
	);
};

export const query = graphql`
	query {
		allContentful404Page {
			nodes {
				subtitle
				title
				image {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				seoTitle
				seoImage {
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
				seoJsonSchema {
					internal {
						content
					}
				}
			}
		}
	}
`;

export default errorPage;
